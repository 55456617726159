import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { msToTime, round, consumoTotal, porcentaje } from "./helper";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#22a374",
    position: "relative",
  },
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  graphic: {
    marginTop: 30,
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  table: {
    minWidth: 700,
  },

  info: {
    marginBottom: 60,
  },
  tab: {
    marginTop: 60,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);
const StyledTableCell3 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const OcupationTable = ({ reportData, project, typeGateway }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleDataOccupationTypeGateway = () => {
    if (typeGateway === "horus_700") {
      return reportData?.getNewDataOccupancy;
    } else if (typeGateway === "horus_g150") {
      return reportData?.getDataReport;
    }
  };

  const totalConsumptionKwh =
    handleDataOccupationTypeGateway()?.totals_consumptions_report_data
      ?.totalConsumptionkWh;

  const totalEfficientConsumption =
    handleDataOccupationTypeGateway()?.totals_consumptions_report_data
      ?.totalEfficientConsumption;

  const totalInefficientConsumption =
    handleDataOccupationTypeGateway()?.totals_consumptions_report_data
      ?.totalInefficientConsumption;

  const totalAverageEfficientPercentage =
    handleDataOccupationTypeGateway()?.totals_consumptions_report_data
      ?.totalAverageEfficientPercentage;

  const totalAverageInefficientPercentage =
    handleDataOccupationTypeGateway()?.totals_consumptions_report_data
      ?.totalAverageInefficientPercentage;

  return (
    <div>
      <div className="_report_energy">
        <div className="_container_energy">
          <div className="__title_energy">
            <span className="__icon_report">
              <i className="fas fa-chart-bar"></i>
            </span>{" "}
            {t(
              "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.GRAPH_TITLE"
            )}
          </div>
          {handleDataOccupationTypeGateway() ? (
            <>
              <TableContainer component={Paper} className="_container_table">
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.NAME"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.WATTS"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.USAGE_TIME"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_CONSUMPTION"
                        )}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">% </StyledTableCell> */}
                      {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_TIME"
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_PERCENTAGE"
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_CONSUMPTION"
                        )}
                      </StyledTableCell>

                      {/* <StyledTableCell align='center'>
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_COST"
                        )}
                      </StyledTableCell> 
                      */}
                      {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_TIME"
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_PERCENTAGE"
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_CONSUMPTION"
                        )}
                      </StyledTableCell>
                      {/* <StyledTableCell align='center'>
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_COST"
                        )}
                      </StyledTableCell> */}
                      {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                      {/* <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.DEVICE_INEFFICIENCY"
                        )}
                      </StyledTableCell> */}
                      {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                      {/* <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_INEFFICIENCY"
                        )}
                      </StyledTableCell> */}
                      {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                      {/* <StyledTableCell align='center'>
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_COST"
                        )}
                      </StyledTableCell> */}
                      {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {handleDataOccupationTypeGateway()?.report_data?.map(
                      (row) => (
                        <StyledTableRow key={row?.name}>
                          <StyledTableCell
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {row?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.watts}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">
                          {msToTime(row?.total_time_on)}
                        </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {row?.total_time_on}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.total_consumption} kWh
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">
                          {porcentaje(
                            round(row?.total_consumption),
                            round(consumoTotal().consumoTotal)
                          )}
                          %
                        </StyledTableCell> */}
                          {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                          {/* <StyledTableCell align="center">
                          {msToTime(row?.efficient_time)}
                        </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {row?.efficient_time}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row?.efficientPercentage}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row?.efficient_consumption} kWh
                          </StyledTableCell>
                          {/* <StyledTableCell align='center'>
                          {project?.prefix_currency}{" "}
                          {project?.kwh_cost * round(row?.efficient_consumption)}{" "}
                        </StyledTableCell> */}
                          {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                          <StyledTableCell3 align="center">
                            {row?.inefficient_time}
                          </StyledTableCell3>

                          <StyledTableCell3 align="center">
                            {row?.inefficientPercentage}
                          </StyledTableCell3>

                          {/* <StyledTableCell align="center">
                          {msToTime(row?.inefficient_time)}
                        </StyledTableCell> */}
                          <StyledTableCell3 align="center">
                            {row?.inefficient_consumption} kWh
                          </StyledTableCell3>
                          {/* <StyledTableCell align='center'>
                          {project?.prefix_currency}{" "}
                          {project?.kwh_cost * round(row?.inefficient_consumption)}
                        </StyledTableCell> */}
                          {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                          {/* <StyledTableCell align="center">
                          {porcentaje(
                            round(row?.inefficient_consumption),
                            round(row?.total_consumption)
                          )}
                          %
                        </StyledTableCell> */}
                          {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                          {/* <StyledTableCell align="center">
                          {porcentaje(
                            round(row?.inefficient_consumption),
                            round(consumoTotal().consumoIneficiente)
                          )}
                          %
                        </StyledTableCell> */}
                          {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                          {/* <StyledTableCell align='center'>
                          {project?.prefix_currency}{" "}
                          {project?.kwh_cost * round(row?.total_consumption)}
                        </StyledTableCell> */}
                          {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                        </StyledTableRow>
                      )
                    )}
                    <StyledTableRow>
                      <StyledTableCell2 align="">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_CONSUMPTION"
                        )}
                      </StyledTableCell2>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell2 align="center">
                        {totalConsumptionKwh} kWh
                      </StyledTableCell2>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center">
                        {totalAverageEfficientPercentage}%
                      </StyledTableCell>
                      <StyledTableCell2 align="center">
                        {totalEfficientConsumption} kWh
                      </StyledTableCell2>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell3 align="center">
                        {totalAverageInefficientPercentage}%
                      </StyledTableCell3>
                      {/* <StyledTableCell align="center"></StyledTableCell> */}
                      {/* <StyledTableCell3 align="center">
                        {round(consumoTotal().consumoIneficiente)} kWh
                        <br></br>
                        {porcentaje(
                          round(consumoTotal().consumoIneficiente),
                          round(consumoTotal().consumoTotal)
                        )}{" "}
                        %
                      </StyledTableCell3> */}
                      <StyledTableCell3 align="center">
                        {totalInefficientConsumption} kWh
                      </StyledTableCell3>
                      {/* <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell> */}
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.NAME"
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.WATTS"
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.USAGE_TIME"
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_CONSUMPTION"
                    )}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">% </StyledTableCell> */}
                  {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                  <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_TIME"
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_CONSUMPTION"
                    )}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.EFFICIENT_COST"
                    )}
                  </StyledTableCell> */}
                  <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_TIME"
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_CONSUMPTION"
                    )}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.INEFFECTIVE_COST"
                    )}
                  </StyledTableCell> */}
                  {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                  {/* <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.DEVICE_INEFFICIENCY"
                    )}
                  </StyledTableCell> */}
                  {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                  {/* <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_INEFFICIENCY"
                    )}
                  </StyledTableCell> */}
                  {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                  {/* <StyledTableCell align="center">
                    {t(
                      "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.COMPONENT_OCUPATION_AND_ENERGY_CONSUMPTION_REPORT.TOTAL_COST"
                    )}
                  </StyledTableCell> */}
                  {/* se comentó debido a que no se usa, descomentar cuando se necesite */}
                </TableRow>
              </TableHead>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OcupationTable;
