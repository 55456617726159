import React, { useState, useEffect, useContext } from "react";
import { AuthContext, AuthProvider } from "../../context/AuthProvider";
import { addDays } from "date-fns";
import { useParams } from "react-router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

//Highchart
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

//Material component and styles
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Loading from "../loading/Loading";
import { withStyles, makeStyles } from "@material-ui/core/styles";

//components
import DatePicker from "../globals/datepicker/datepicker";
import MessageError from "../message/MessageError";
import Modal from "../modal/Modal";

//Helpers
import {
  handle_start_and_end_time,
  handleQueryReport,
  back,
  next,
} from "../reports/GeneralOcupation/helper";

//query and mutation
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { PDF_GENERAL_OCUPATION_REPORT } from "../../controllers/pdfControllers";
import { GET_GENERAL_OCCUPATION_REPORT } from "../../controllers/reportController";
import html2canvas from "html2canvas";

//multilanguage
import { useTranslation } from "react-i18next";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },

  body: {
    minBlockSize: 650,
    fontSize: 14,
  },
}))(TableCell);

Highcharts.setOptions({
  lang: {
    resetZoom: "Quitar zoom",
    resetZoomTitle: "Quita todo el zoom aplicado",
  },
});

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableTitle = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableCell3 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

//Comentado 16/09/2024
// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

const GeneralOccupation = (props) => {
  const { projectData } = props;
  const { t } = useTranslation();
  const [basePdf, setBasePdf] = React.useState();
  const [report, setReport] = React.useState();
  const [pdfGenerator] = useMutation(PDF_GENERAL_OCUPATION_REPORT);
  const [projectT, setProjectT] = React.useState();

  const {
    downloadGeneralOcupation,
    setDownloadGeneralOcupation,
    dateReport,
    setDateReport,
    setOpenDateCustom,
    openDateCustom,
    graphic,
    setGraphic,
    pie,
    setPie,
    roomPdf,
    setRoomPdf,
    startTimes,
    setStartTimes,
    finishTimes,
    setFinishTimes,
    loadingPdf,
    setLoadingPdf,
    openModal,
    setOpenModal,
    calendarDatePicker, // calendar
    setCalendarDatePicker, // setCalendar
    dateCalendarTemp,
    setDateCalendar,
    setIsOpenCalendarCustom,
    rangeDatePicker,
    setRangeDatePicker,
  } = useContext(AuthContext);
  const [project, setProject] = useState(projectData);
  const [load, setLoad] = useState(false);
  const [oldTime, setOldTime] = useState("");
  const { id } = useParams();
  const [dataPdfGeneral, setDataPdfGeneral] = React.useState({
    image: [graphic, pie],
    data: { room: roomPdf },
    startTime: startTimes,
    finishTime: finishTimes,
    nameProject: localStorage.getItem("nameProject"),
    userName: localStorage.getItem("name"),
    imageProject: localStorage.getItem("imgProject"),
    datePrint: localStorage.getItem("localTime"),
  });
  const classes = useStyles();
  const printRef = React.useRef();
  const printRefP = React.useRef();

  useEffect(() => {
    if (oldTime) {
      if (startTimes == oldTime) {
        setFinishTimes("");
      }
      if (startTimes == oldTime) {
        setFinishTimes("");
      }
    }
  }, [oldTime]);

  useEffect(() => {
    if (graphic) {
      imagePie();
    }
  }, [graphic]);

  useEffect(() => {
    if (pie) {
      dataPdf();
    }
  }, [pie]);

  useEffect(() => {
    if (basePdf) {
      decodePdf();
    }
  }, [basePdf]);

  const decodePdf = () => {
    const linkSource = `data:application/pdf;base64,${basePdf}`;
    const downloadLink = document.createElement("a");
    const { start: initial, end: final } = handle_start_and_end_time({
      startDate: rangeDatePicker[0]?.startDate,
      endDate: rangeDatePicker[0]?.endDate,
      key: "selection",
    });
    const fileName = `${t(
      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.FILE_NAME"
    )} - ${localStorage.getItem("nameProject")} - (${initial} - ${final}).pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    /* let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(basePdf) +
        "'></iframe>"
    ); */
    setLoadingPdf(false);
    setGraphic("");
    setPie("");
    setBasePdf("");
  };

  const imageGraphic = async () => {
    try {
      const element = printRef?.current;
      if (element) {
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL("image/jpg");
        setGraphic(data.slice(22));
      } else {
        setGraphic("");
        setLoadingPdf(false);
      }
    } catch (error) {
      console.log(error);
    }

    //var cadena = data.slice(22);
    /* if (typeof link.download === "string") {
      link.href = data;
      link.download = "imgGraph.jpg";
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }  */
    /* imagePie() */
  };

  const imagePie = async () => {
    try {
      const elementTwo = printRefP.current;
      if (elementTwo) {
        const canvas = await html2canvas(elementTwo);
        const data = canvas.toDataURL("image/jpg");
        setPie(data.slice(22));
      } else {
        setPie("");
        setLoadingPdf(false);
      }
    } catch (error) {
      console.log(error);
    }

    /* else {
    /* if (typeof link.download === "string") {
      link.href = data;
      setPie(data);
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } */
  };

  async function generalPdf() {
    setLoadingPdf(true);
    try {
      await imageGraphic();
    } catch (error) {
      console.log(error);
    }
  }

  // fechas para la descarga de PDF
  let pdfFormatStartTime = rangeDatePicker[0]?.startDate?.getTime();
  let pdfFormatEndTime = rangeDatePicker[0]?.endDate?.getTime();

  function dataPdf() {
    try {
      pdfGenerator({
        variables: {
          image: [graphic, pie],
          data: roomPdf,
          startTime: String(pdfFormatStartTime),
          finishTime: String(pdfFormatEndTime),
          nameProject: dataPdfGeneral.nameProject,
          userName: dataPdfGeneral.userName,
          imageProject: dataPdfGeneral.imageProject,
          datePrint: dataPdfGeneral.datePrint,
        },
      })
        .then((resul) => {
          setBasePdf(resul.data.pdfGeneralOcupationReport);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  const [getData, { loading, error, data }] = useLazyQuery(
    GET_GENERAL_OCCUPATION_REPORT,
    {
      fetchPolicy: "no-cache",
    }
  );

  React.useEffect(() => {
    if (data) {
      setProjectT(data.getGeneralOccupationReport);
      setReport(data.getGeneralOccupationReport.rooms);
      setDownloadGeneralOcupation(data.getGeneralOccupationReport.rooms);
      setRoomPdf({
        rooms: data.getGeneralOccupationReport.rooms,
        project: data.getGeneralOccupationReport.project,
      });
    }
  }, [data]);

  React.useEffect(() => {
    setLoad(true);
    // let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    // let day_yesterday = new Date(yesterday_ms);
    // let day_start = day_yesterday.getDate();
    // let mounth_start = day_yesterday.getMonth() + 1;
    // let year_start = day_yesterday.getFullYear();
    // let yesterday = year_start + "/" + mounth_start + "/" + day_start;

    if (openModal) {
      if (project?.start_time === "00:00" || project?.start_time === "0:00") {
        var tmpDate = new Date().getTime();
        let yesterday_ms = parseInt(tmpDate, 10) - 86400000;

        setDateReport([
          {
            startDate: new Date(yesterday_ms),
            endDate: addDays(new Date(yesterday_ms), 0),
            key: "selection",
          },
        ]);

        const { start: initial, end: final } = handle_start_and_end_time({
          startDate: new Date(yesterday_ms),
          endDate: addDays(new Date(yesterday_ms), 0),
          key: "selection",
        });

        getData({
          variables: {
            ProjectId: parseInt(id),
            start_time: initial,
            end_time: final,
          },
        });
      } else if (project?.start_time === "15:00") {
        var tmpDate = new Date().getTime();
        let yesterday_ms = parseInt(tmpDate, 10) - 172800000;

        setDateReport([
          {
            startDate: new Date(yesterday_ms),
            endDate: addDays(new Date(yesterday_ms), 0),
            key: "selection",
          },
        ]);

        const { start: initial, end: final } = handle_start_and_end_time({
          startDate: new Date(yesterday_ms),
          endDate: addDays(new Date(yesterday_ms), 0),
          key: "selection",
        });

        getData({
          variables: {
            ProjectId: parseInt(id),
            start_time: initial,
            end_time: final,
          },
        });
      }
    }
  }, [openModal]);

  
  //if (loading || !projectT || !report) return <Loading />;
  /* if (error) {
    return (
      <div style={{}}>
        <Construction />
      </div>
    );
  } */

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  //   setStart_time(date.getTime());
  // };

  // const handleDateChange2 = (date) => {
  //   setSelectedDate2(date);
  //   setEnd_time(date.getTime());
  // };

  // function msToTime(duration) {
  //   var seconds = parseInt((duration / 1000) % 60),
  //     minutes = parseInt((duration / (1000 * 60)) % 60),
  //     hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  //   hours = hours < 10 ? "0" + hours : hours;
  //   minutes = minutes < 10 ? "0" + minutes : minutes;
  //   seconds = seconds < 10 ? "0" + seconds : seconds;

  //   return hours + ":" + minutes;
  // }
  // function total(array) {
  //   var Totales = {
  //       watts: 0,
  //       consumoTotal: 0,
  //       consumoEficiente: 0,
  //       consumoIneficiente: 0,
  //     },
  //     i = 0;
  //   for (i = 0; i < array.length; i++) {
  //     Totales.watts += round(array[i].watts);
  //     Totales.consumoTotal += round(array[i].total_consumption);
  //     Totales.consumoEficiente += round(array[i].efficient_consumption);
  //     Totales.consumoIneficiente += round(array[i].inefficient_consumption);
  //   }

  //   return Totales;
  // }

  function round(num, decimales = 1) {
    var signo = num >= 0 ? 1 : -1;
    num = num * signo;
    if (decimales === 0)
      //con 0 decimales
      return signo * Math.round(num);
    // round(x * 10 ^ decimales)
    num = num.toString().split("e");
    num = Math.round(
      +(num[0] + "e" + (num[1] ? +num[1] + decimales : decimales))
    );
    // x * 10 ^ (-decimales)
    num = num.toString().split("e");
    return signo * (num[0] + "e" + (num[1] ? +num[1] - decimales : -decimales));
  }

  // const handleQueryReport = () => {
  //   let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  //   let day_start = dateReport[0].startDate.getDate();
  //   let mounth_start = dateReport[0].startDate.getMonth() + 1;
  //   let year_start = dateReport[0].startDate.getFullYear();
  //   let day_end = dateReport[0].endDate.getDate();
  //   let mounth_end = dateReport[0].endDate.getMonth() + 1;
  //   let year_end = dateReport[0].endDate.getFullYear();
  //   var day_time_zone = year_start + "/" + mounth_start + "/" + day_start;

  //   var day_time_end_zone = year_end + "/" + mounth_end + "/" + day_end;
  //   var param = dateReport[0].startDate.toString().substring(0, 16) + "00:00:00 GMT";
  //   var param_end = dateReport[0].endDate.toString().substring(0, 16) + "23:59:59 GMT";

  //   var tempPara =
  //     new Date(param).getTime() - parseInt(project, 10) * HORA_EN_MILISEGUNDO;
  //   var tmpDate = new Date(day_time_zone).getTime();

  //   const temEndDate = new Date(day_time_end_zone).getTime();

  //   //openDateCusom
  //   setOpenDateCustom(false);
  //   //Parámetro inicial
  //   setParameterMin(tempPara);
  //   //Fecha inicial
  //   setDateMin(tmpDate);
  //   //parámetro final
  //   setParameterMax(temEndDate);
  //   //Fecha final
  //   setDateMax(temEndDate);
  //   //datePdf
  //   setStartTimes(day_time_zone);
  //   setOldTime(day_time_end_zone);
  //   setFinishTimes(day_time_end_zone);
  //   getData({
  //     variables: {
  //       ProjectId: parseInt(id),
  //       start_time: String(day_time_zone),
  //       end_time: String(day_time_end_zone),
  //     },
  //   });
  // };

  function percentege(data, total) {
    if (total == 0) {
      return 0;
    }
    return Number.parseFloat((data / total) * 100).toFixed(1);
  }

  return (
    <Modal
      handleClick={generalPdf}
      timerProject={project?.start_time}
      title={`${localStorage.getItem("nameProject")} - ${t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.HEADER_TITLE"
      )}`}
    >
      {loading || !projectT || !report ? (
        <Loading />
      ) : (
        <div>
          {/* <DateCustom handleQueryReport={handleQueryReport} /> */}
          <DatePicker
            type="range"
            component={"generalOccupation"}
            handleQuery={() => handleQueryReport(id, rangeDatePicker, getData)}
            back={() =>
              back(id, project, getData, rangeDatePicker, setRangeDatePicker)
            }
            next={() =>
              next(id, project, getData, rangeDatePicker, setRangeDatePicker)
            }
            selectOption={{ changeInside: false, stateRoomId: 0 }}
            timerProject={project?.start_time}
          />
          {report && (
            <div>
              {projectT && projectT?.rooms.length != 0 && (
                <div className="__container_graphic_report_general_ocupation_title__">
                  <span className="__icon_report">
                    <i className="fas fa-chart-bar"></i>
                  </span>{" "}
                  {t(
                    "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.EFFICIENCY_GRAPHIES"
                  )}
                </div>
              )}
            </div>
          )}
          {projectT.rooms.length == 0 ? (
            <div className="__container_msg_report_empty__">
              <span>
                <i class="far fa-calendar-alt"></i>{" "}
                {t(
                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.USAGE_INFO"
                )}
              </span>
            </div>
          ) : (
            <></>
          )}
          {/* <TableContainer component={Paper}  */}
          {report && (
            <div>
              {projectT && projectT?.rooms.length != 0 && (
                <div className="__graphic_report_ocupation_general_container__">
                  <div ref={printRef}>
                    <div className="__graphic_pie_table_ocupation_general__">
                      <HighchartsReact
                        // constructorType="stockChart"
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: "column",
                          },
                          title: {
                            text: "",
                          },

                          xAxis: {
                            categories: projectT?.categoriesOccupationReport,
                          },
                          yAxis: {
                            min: 0,
                            title: {
                              text: "Total consumo (Kw h)",
                            },
                            stackLabels: {
                              enabled: true,
                              style: {
                                fontWeight: "bold",
                                color:
                                  // theme
                                  (Highcharts?.defaultOptions?.title?.style &&
                                    Highcharts?.defaultOptions?.title?.style
                                      ?.color) ||
                                  "gray",
                              },
                            },
                          },
                          // legend: {
                          //   align: "right",
                          //   x: 0,
                          //   verticalAlign: "top",
                          //   y: 0,
                          //   floating: false,
                          //   backgroundColor:
                          //     Highcharts.defaultOptions.legend.backgroundColor ||
                          //     "white",
                          //   borderColor: "#CCC",
                          //   borderWidth: 1,
                          //   shadow: false,
                          // },
                          tooltip: {
                            headerFormat: "<b>{point.x}</b><br/>",
                            pointFormat:
                              "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
                          },
                          plotOptions: {
                            column: {
                              stacking: "normal",
                              maxPointWidth: 50,
                              dataLabels: {
                                enabled: false,
                              },
                            },
                          },
                          series: [
                            projectT?.graphOcupationReport[0],
                            projectT?.graphOcupationReport[1],
                          ],
                        }}
                      />
                    </div>
                  </div>
                  <div ref={printRefP}>
                    <div className="__graphic_pie_table_ocupation_general__">
                      <HighchartsReact
                        // constructorType="stockChart"
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            zoomType: "x",
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: "pie",
                          },
                          title: {
                            text: "",
                          },
                          tooltip: {
                            pointFormat:
                              "{series.name}: <b>{point.percentage:.1f}%</b>",
                          },
                          accessibility: {
                            point: {
                              valueSuffix: "%",
                            },
                          },
                          plotOptions: {
                            pie: {
                              size: 200,
                              allowPointSelect: true,
                              cursor: "pointer",
                              dataLabels: {
                                enabled: true,
                                format:
                                  "<b>{point.name}</b>: {point.percentage:.1f} %",
                              },
                              //   showInLegend: true
                            },
                          },
                          series: [projectT?.GraphPie[0]],
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/*  <button type="button" onClick={imageGraphic, seeData }>
              capture image
            </button> */}

              <div className="__table_container_report_general_total__">
                <div className="__title_energy">
                  <span className="__icon_report">
                    <i className="fas fa-chart-bar"></i>
                  </span>{" "}
                  {t(
                    "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.GENERAL_PROJECT_REPORT_TABLE_TITLE"
                  )}
                </div>
                <Table className={classes.table} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.TOTAL_CONSUMPTION"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.EFFICIENT_CONSUMPTION"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.EFFICIENT"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.INEFFECTIVE_CONSUMPTION"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.INEFFECTIVE"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.PERCENTAGE"
                        )}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <StyledTableCell2
                        component="th"
                        align="center"
                        scope="row"
                      >
                        Total
                      </StyledTableCell2>
                      <TableCell component="th" align="center" scope="row">
                        {round(projectT?.project[0]?.total.total_consumption)}{" "}
                        kW h
                      </TableCell>
                      <StyledTableCell2
                        component="th"
                        align="center"
                        scope="row"
                      >
                        {round(
                          projectT?.project[0]?.total?.efficient_consumption
                        )}{" "}
                        kW h
                      </StyledTableCell2>
                      <StyledTableCell2 align="center">
                        {round(projectT?.project[0]?.total?.efficient_percent)}{" "}
                        %
                      </StyledTableCell2>
                      <StyledTableCell3 align="center">
                        {round(
                          projectT?.project[0]?.total?.inefficient_consumption
                        )}{" "}
                        kW h
                      </StyledTableCell3>
                      <StyledTableCell3 align="center">
                        {round(
                          projectT?.project[0]?.total?.inefficient_percent
                        )}{" "}
                        %
                      </StyledTableCell3>
                      <StyledTableCell2 align="center">
                        {round(
                          projectT?.project[0]?.total?.porcent_time_ocupation
                        )}{" "}
                        %
                      </StyledTableCell2>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell2
                        component="th"
                        align="center"
                        scope="row"
                      >
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.AVERAGE_PER_DAY"
                        )}
                      </StyledTableCell2>
                      <TableCell component="th" align="center" scope="row">
                        {round(
                          projectT?.project[0]?.averageDayProject
                            ?.total_consumption
                        )}
                        kW h /
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.DAY"
                        )}
                      </TableCell>
                      <StyledTableCell2
                        component="th"
                        align="center"
                        scope="row"
                      >
                        {round(
                          projectT?.project[0]?.averageDayProject
                            ?.efficient_consumption
                        )}
                        kW h /
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.DAY"
                        )}
                      </StyledTableCell2>
                      <StyledTableCell2 align="center">
                        {round(
                          projectT?.project[0]?.averageDayProject
                            ?.efficient_percent
                        )}{" "}
                        %
                      </StyledTableCell2>
                      <StyledTableCell3 align="center">
                        {round(
                          projectT?.project[0]?.averageDayProject
                            ?.inefficient_consumption
                        )}
                        kW h /
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.DAY"
                        )}
                      </StyledTableCell3>
                      <StyledTableCell3 align="center">
                        {round(
                          projectT?.project[0]?.averageDayProject
                            ?.inefficient_percent
                        )}{" "}
                        %
                      </StyledTableCell3>
                      <StyledTableCell2 align="center">
                        {round(
                          projectT?.project[0]?.averageDayProject
                            ?.porcent_time_ocupation
                        )}{" "}
                        %
                      </StyledTableCell2>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell2
                        component="th"
                        align="center"
                        scope="row"
                      >
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.AVERAGE_PER_DAY_IN_ROOM"
                        )}
                      </StyledTableCell2>
                      <TableCell component="th" align="center" scope="row">
                        {round(
                          projectT?.project[0]?.averageDayRoom
                            ?.total_consumption
                        )}
                        kW h /
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.DAY"
                        )}
                      </TableCell>
                      <StyledTableCell2
                        component="th"
                        align="center"
                        scope="row"
                      >
                        {round(
                          projectT?.project[0]?.averageDayRoom
                            ?.efficient_consumption
                        )}
                        kW h /
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.DAY"
                        )}
                      </StyledTableCell2>
                      <StyledTableCell2 align="center">
                        {round(
                          projectT?.project[0]?.averageDayRoom
                            ?.efficient_percent
                        )}{" "}
                        %
                      </StyledTableCell2>
                      <StyledTableCell3 align="center">
                        {round(
                          projectT?.project[0]?.averageDayRoom
                            ?.inefficient_consumption
                        )}{" "}
                        kW h /
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.DAY"
                        )}
                      </StyledTableCell3>
                      <StyledTableCell3 align="center">
                        {round(
                          projectT?.project[0]?.averageDayRoom
                            ?.inefficient_percent
                        )}{" "}
                        %
                      </StyledTableCell3>
                      <StyledTableCell2
                        component="th"
                        align="center"
                        scope="row"
                      >
                        {round(
                          projectT?.project[0]?.averageDayRoom
                            ?.porcent_time_ocupation
                        )}{" "}
                        %
                      </StyledTableCell2>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <br></br>
              {projectT.rooms.length != 0 && (
                <div className="__areas_report_ocupaction_general__">
                  <span>
                    <hr></hr>
                  </span>
                  <span>
                    {t(
                      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.AREAS"
                    )}
                  </span>
                  <span>
                    <hr />
                  </span>
                </div>
              )}

              <br></br>

              {report.map((report) => (
                <>
                  {report.visible && (
                    <div className="_report_energy">
                      <div className="_container_energy">
                        <div className="__title_energy">
                          <span className="__icon_report">
                            <i className="fas fa-chart-bar"></i>
                          </span>{" "}
                          {report.alias}
                        </div>
                        <Table
                          className={classes.table}
                          aria-label="caption table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                {t(
                                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.DATE"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.TOTAL_CONSUMPTION"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.EFFICIENT_CONSUMPTION"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.EFFICIENT"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.INEFFECTIVE_CONSUMPTION"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.INEFFECTIVE"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.PERCENTAGE"
                                )}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {" "}
                            {report.range.map((row) => (
                              <TableRow key={row.date}>
                                <TableCell component="th" scope="row">
                                  {row.date}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  align="center"
                                  scope="row"
                                >
                                  {round(row?.total_consumption)} kW h
                                </TableCell>
                                <StyledTableCell2 align="center">
                                  {round(row?.efficient_consumption)} kW h
                                </StyledTableCell2>
                                <StyledTableCell2 align="center">
                                  {round(row?.efficient_percent)} %
                                </StyledTableCell2>
                                <StyledTableCell3 align="center">
                                  {round(row?.inefficient_consumption)} kW h
                                </StyledTableCell3>
                                <StyledTableCell3 align="center">
                                  {round(row?.inefficient_percent)} %
                                </StyledTableCell3>
                                <TableCell align="center">
                                  {round(row?.porcent_time_ocupation)} %
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              {" "}
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableCell2
                                align="center"
                                className="__color_title_reportOcupation__"
                              >
                                {t(
                                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.FINAL_RESULT"
                                )}
                              </StyledTableCell2>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"></TableCell>

                              <TableCell align="center">
                                {round(report?.total?.total_consumption)} kW hm{" "}
                              </TableCell>
                              <StyledTableCell2 align="center">
                                {round(report?.total?.efficient_consumption)} kW
                                h
                              </StyledTableCell2>
                              <StyledTableCell2 align="center">
                                {round(report?.total?.efficient_percent)} %
                              </StyledTableCell2>
                              <StyledTableCell3 align="center">
                                {round(report?.total?.inefficient_consumption)}{" "}
                                kW h
                              </StyledTableCell3>
                              <StyledTableCell3 align="center">
                                {round(report?.total?.inefficient_percent)} %
                              </StyledTableCell3>
                              <TableCell align="center"></TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableCell2
                                align="center"
                                className="__color_title_reportOcupation__"
                              >
                                {t(
                                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.AVERAGE"
                                )}
                              </StyledTableCell2>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                              <StyledTableTitle
                                align="center"
                                className="__color_title_reportOcupation__"
                              ></StyledTableTitle>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"></TableCell>

                              <TableCell align="center">
                                {round(report.average.total_consumption)} kW h{" "}
                              </TableCell>
                              <StyledTableCell2 align="center">
                                {round(report?.average?.efficient_consumption)}{" "}
                                kW h
                              </StyledTableCell2>
                              <StyledTableCell2 align="center">
                                {round(report?.average?.efficient_percent)} %
                              </StyledTableCell2>
                              <StyledTableCell3 align="center">
                                {round(
                                  report?.average?.inefficient_consumption
                                )}{" "}
                                kW h
                              </StyledTableCell3>
                              <StyledTableCell3 align="center">
                                {round(report?.average?.inefficient_percent)} %
                              </StyledTableCell3>
                              <TableCell align="center">
                                {round(report?.average?.porcent_time_ocupation)}{" "}
                                %
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
          {/* </TableContainer> */}
        </div>
      )}
      <MessageError
        messageText={t(
          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_OCCUPATION_VIEW.MESSAGE_ERROR"
        )}
      />
    </Modal>
  );
};

export default GeneralOccupation;
