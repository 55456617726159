import React, { useEffect, useState, useContext, useRef } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  Dialog,
  AppBar,
  Typography,
  Slide,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { GET_INFO_MEASURER_BY_PROJECT } from "../../../controllers/measurersController";
import Loading from "../../../components/loading/Loading";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { AuthContext } from "../../../context/AuthProvider";
import { addDays } from "date-fns";
import MessageInfo from "../../../components/message/MessageInfo";
import MessageSuccess from "../../../components/message/MessageSuccess";
import MessageError from "../../../components/message/MessageError";
import { Hidden } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
//components
import Areas from "../areas/Areas";
import ProjectCardAreaControl from "../../../components/projects/ProjectCardAreaControl";
import Measurer from "../../../components/measurer/Measurer";
import MeasurerGeneral from "../../../components/highcharts/MeasurerGeneral";
import MeasurerDetails from "../../../components/highcharts/MeasurerDetails";
import HeavyDuty from "../../../components/measurer/HeavyDuty";
import MacroMeterCard from "../../../components/measurer/macroMeterCard";
import { NewMacrometerCard } from "../../../components/NewMesurer/NewMacrometerCard";
import { secondClient } from "../../../index";
//multilanguage
import { useTranslation } from "react-i18next";
//function
let CLIENT = null;
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#3f9948",
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const fech = async () => {
  const fechaHoy = new Date();
  const mesActual = fechaHoy.getMonth() + 1;
  const year = fechaHoy.getFullYear().toString();
  const dia = fechaHoy.getDate().toString();
  const fechaI = `${year}-${mesActual}-${dia} 00:00:00`;
  const fI = Date.parse(fechaI);
};
const Measurers = () => {
  const { t } = useTranslation();
  const {
    setEvent,
    setDateReport,
    setactiveNextBack,
    loadingPdf,
    setSuccessMessage,
    setErrorMessage,
    setOpenModal,
    serial,
    setSerial,
    setDateMacrometers,
    setDateMacrometersTwo,
    setValueDatepicker,
    permissionsRoleUser,
    verifyMacromeasure,
    setVerifyMacromeasure,
    dataMacrometerVariables,
  } = useContext(AuthContext);
  const [openMeasurer, setOpenMeasurer] = useState(false);
  const [openNewMeasurer, setOpenNewMeasurer] = useState(false);
  const [isReport, SetIsReport] = useState(false);
  const [measurerComponent, setMeasurerComponent] = useState([]);
  const [modelM5, setModelM5] = useState([]);
  const [modelHse, setModelHse] = useState([]);
  const [serials, setSerials] = useState([]);
  const classes = useStyles();
  const pdfExportComponent = React.useRef(null);
  const childRef = useRef();
  //Socket
  const { REACT_APP_SOCKET_STAGING, REACT_APP_SOCKET_PRODUCTION } = process.env;
  const handleClose = () => {
    setOpenMeasurer(false);
    setactiveNextBack(false);
  };
  const handleOpenReportMeasurer = () => {
    setOpenMeasurer(true);
    SetIsReport(false);
    setactiveNextBack(true);
    setDateReport([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: "selection",
      },
    ]);
  };
  const { data, loading, refetch } = useQuery(GET_INFO_MEASURER_BY_PROJECT, {
    variables: { ProjectId: parseInt(localStorage.getItem("id"), 10) },
    fetchPolicy: "network-only",
  });
  const startTimeProject = data?.getProjectByIdHeader?.start_time;
  const dataCity = data?.getProjectByIdHeader?.city;
  const saveData = () => {
    if (dataMacrometerVariables?.length !== 0) {
      const modelM5d = dataMacrometerVariables?.filter(
        (i) => i.model === "SMD630MCT"
      );
      setModelM5(modelM5d);
      const modelHsed = data?.getMettersInProjets.filter(
        (i) => i.model === "HSE-DC-GSM"
      );
      setModelHse(modelHsed);
    }
  };
  let dataMacrometersVariables = JSON.parse(
    sessionStorage.getItem("resultadoFinal")
  );
  useEffect(() => {
    if (data) {
      setMeasurerComponent(data.getInfoMetersByProject);
      saveData();
    }
  }, [data]);
  useEffect(() => {
    if (CLIENT) {
      CLIENT.onerror = CLIENT.onopen = CLIENT.onclose = null;
      CLIENT.close();
    }
    CLIENT = new W3CWebSocket(`${REACT_APP_SOCKET_PRODUCTION}`);
    CLIENT.onopen = () => {
      if (localStorage.getItem("id"))
        CLIENT.send(
          JSON.stringify({ channel: `Meters-${localStorage.getItem("id")}` })
        );
    };
    CLIENT.onclose = () => {
      CLIENT = null;
    };
    return () => {
      if (CLIENT) CLIENT.close();
    };
  }, [localStorage.getItem("id")]);
  useEffect(() => {
    CLIENT.onmessage = (message) => {
      let data = JSON.parse(message.data);
      if (data.message) {
        if (data.message == "Device update") {
          updateState(data);
        } else if (data.message == "Orden enviada y ejecutada correctamente.") {
          setSuccessMessage(true);
        } else {
          setErrorMessage(true);
        }
      }
    };
  }, [measurerComponent]);
  async function updateState(dev) {
    measurerComponent.findIndex((elemento, indice) => {
      if (elemento.id === dev.id_device) {
        let tempDevice = [...measurerComponent];
        tempDevice[indice] = { ...tempDevice[indice] };
        tempDevice[indice].variables = { ...tempDevice[indice].variables };
        if (dev.action == "Status") {
          tempDevice[indice].variables.Status = dev.status;
        } else if (dev.action == "KWH") {
          tempDevice[indice].variables.KWH = dev.status;
        } else if (dev.action == "Watts") {
          tempDevice[indice].variables.Watts = dev.status;
        }
        setMeasurerComponent(tempDevice);
      }
    });
  }
  function Send(data, state) {
    if (data.CategoryId == 8) {
      CLIENT.send(
        JSON.stringify({
          // serial: dataDevice.getAllDevices.Controller.serial,
          // id_device: device.id_in_controller,
          variable: "ChangeStatus",
          action: "status",
          value: state ? "1" : "0",
        })
      );
    }
  }
  // const {
  //   data: newData,
  //   loading: newLoading,
  //   refetch: newRefetch,
  // } = useQuery(GET_METER_HISTORY_VARIABLES, {
  //   client: secondClient,
  //   variables: { serial: serials[0] },
  //   fetchPolicy: 'no-cache',
  // });
  useEffect(() => {
    CLIENT.onmessage = (message) => {
      const _data = JSON.parse(message.data);
      setEvent(_data);
    };
  }, [localStorage.getItem("id")]);
  return (
    <>
      {data && (
        <div className="_measurers_container_">
          <div className="_measurers_container_header_">
            <div className="_measurers_container_logo">
              <img
                src={data?.getProjectByIdHeader?.img}
                className="__measurers__img"
                alt="Logo"
                width="180"
                height="180"
              />
            </div>
            <div className="_measurers_container_info_">
              <div className="_measurers__info_">
                <ProjectCardAreaControl data={data?.getProjectByIdHeader} />
              </div>
              <div className="_measurers__filters_">
                <div className="_measurer_filter_name_">
                  {t(
                    "VIEW_MEASURERS.COMPONENT_MEASURER_HEADER_CONTAINER.MEASURERS"
                  )}
                </div>

                <div className="_measurer__report_">
                  <div
                    className="_measurer_report_option_"
                    onClick={
                      permissionsRoleUser?.permissionsRole?.MedMacVer
                        ? () => {
                            handleOpenReportMeasurer();
                          }
                        : false
                    }
                  >
                    <span>
                      <i className="fas fa-tachometer-alt"></i>{" "}
                      {t(
                        "VIEW_MEASURERS.COMPONENT_MEASURER_HEADER_CONTAINER.GENERAL_MEASURER"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="_measurers_container_location_">
            <>
              {dataMacrometersVariables?.map((i, key) => (
                <NewMacrometerCard
                  key={key}
                  alias={i?.alias}
                  serial={i?.serial}
                  variable={i?.variable}
                  city={dataCity}
                  startTimeProject={startTimeProject}
                  timeZone={data?.getProjectByIdHeader?.time_zone}
                />
              ))}
            </>
          </div>
          <div className="_measurers_container_location_">
            {modelHse.map((i, key) =>
              i.Metters.map((j) => (
                <MacroMeterCard
                  key={key}
                  alias={j.alias}
                  serial={j.serial}
                  name={data?.getProjectByIdHeader?.name}
                  timeZone={data?.getProjectByIdHeader?.time_zone}
                />
              ))
            )}
          </div>
          <div className="_measurers_container_location_">
            {data?.getInfoMetersByProject?.map((key) =>
              key.CategoryId === 8 ? (
                <HeavyDuty
                  data={key}
                  send={Send}
                  timeZone={data?.getProjectByIdHeader?.time_zone}
                />
              ) : (
                <Measurer
                  {...{
                    refetch,
                  }}
                  data={key}
                  timeZone={data?.getProjectByIdHeader?.time_zone}
                />
              )
            )}
          </div>
          <MessageInfo />
          <MessageError />
          <MessageSuccess />
          {permissionsRoleUser?.permissionsRole?.MedMacVer ? (
            <Dialog
              fullScreen
              open={openMeasurer}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {data?.getProjectByIdHeader?.name} -{" "}
                    {!isReport
                      ? t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.HEADER_MODAl_TITLE"
                        )
                      : t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.READING"
                        )}
                  </Typography>
                  {!isReport && (
                    <Hidden mdDown>
                      <LoadingButton
                        onClick={() => childRef.current.measuresGeneralPdf()}
                        endIcon={<DownloadIcon />}
                        loadingPosition="end"
                        color="inherit"
                      >
                        {t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.DOWNLOAD"
                        )}
                      </LoadingButton>
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loadingPdf}
                        onClick={loadingPdf}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Hidden>
                  )}
                </Toolbar>
              </AppBar>
              {isReport ? (
                <MeasurerDetails
                  projectID={data?.getProjectByIdHeader?.id}
                  timeZone={data?.getProjectByIdHeader?.time_zone}
                  serial={serial}
                />
              ) : (
                <MeasurerGeneral
                  ref={childRef}
                  projectID={data?.getProjectByIdHeader?.id}
                  timeZone={data?.getProjectByIdHeader?.time_zone}
                />
              )}
            </Dialog>
          ) : null}
        </div>
      )}
    </>
  );
};
export default Measurers;